import React, { useRef } from 'react';
import {
    Heading,
    Text,
    Box,
    Flex,
    Grid,
    Icon,
    useTheme
} from '@chakra-ui/core';

import './app.css';

const DownArrow = () => {
    return <>
        <Icon name="chevron-down" className="animated infinite flash"></Icon>
        <Icon name="chevron-down" className="animated infinite flash delay-1"></Icon>
        <Icon name="chevron-down" className="animated infinite flash delay-2"></Icon>
    </>;
}

const CenterFlex = (props) => {
    return <Flex {...props} className={props.className + " flex"} />;
}

const App = () => {
    const theme = useTheme();
    return <>
        <CenterFlex className="main-content">
            <Heading size="2xl">Donate</Heading>
            <Heading size="sm">to Lopoly</Heading>
            <br />
            <Box w="60vmin" textAlign="center">
                <Text>
                    Lopoly is a specialized set of tools designed to support the competitive programming society.
                    As a competitive programmer, I have joined many competitive programming events.
                    To contribute back to the society, I wish to build Lopoly, a toolkit for competitive programming problemsetters and camp organizers.
            </Text>
                <br />
                <Text>
                    Without your help, Lopoly could not be succeeded. If you are interested in supporting Lopoly, you can donate here.
            </Text>
            </Box>
            <br />
            <CenterFlex h="20vh">
                <Heading size="md">Donate by Banking</Heading>
                <Heading size="md">Donate by Ethereum</Heading>
                <CenterFlex h="10vh">
                    <DownArrow />
                </CenterFlex>
            </CenterFlex>
        </CenterFlex>
        <Box
            className="main-content"
            backgroundColor={theme.colors.gray[500]}
            color={theme.colors.gray[50]}
        >
            <Grid h="80vh" templateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]}>
                <CenterFlex overflow="hidden" marginTop="10vmin" marginBottom="5vmin" borderRightWidth={["0px", "0px", "0px", "2px"]}>
                    <Heading>
                        Bank Account
                    </Heading>
                    <br />
                    <Heading size="lg">
                        TBD
                    </Heading>
                </CenterFlex>
                <CenterFlex overflow="hidden" marginTop="10vmin" marginBottom="5vmin">
                    <Heading>
                        Ethereum Address
                    </Heading>
                    <br />
                    <Heading size="lg" overflowWrap="break-word" w="90%" textAlign="center">
                        0xdDadf37235666D699d8f03b2002E93Eac56018e7
                    </Heading>
                </CenterFlex>
            </Grid>
            <Box textAlign="center" marginTop="5vmin" marginLeft="auto" marginRight="auto">
                <Heading>
                    Thank you for all of your support!
                </Heading>
                <br />
                <Text>
                    © 2020 Lopoly
                </Text>
            </Box>
        </Box>
    </>;
}


export default App;
