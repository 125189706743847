import React from 'react';
import ReactDOM from 'react-dom';
import { theme, ThemeProvider, CSSReset } from "@chakra-ui/core";

import App from './app/app';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CSSReset />
        <App />
    </ThemeProvider>,
    document.getElementById('root'));
